import React from 'react';

const Panel5SubmittedValues = ({ submittedValues }) => {
  return (
    <div>
      <h3>Submitted Values:</h3>
      {submittedValues ? (
        <pre>{JSON.stringify(submittedValues, null, 2)}</pre>
      ) : (
        <p>No values submitted yet.</p>
      )}
    </div>
  );
};

export default Panel5SubmittedValues;