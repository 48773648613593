import React, { useState } from 'react';
import { Layout, Palette } from 'lucide-react';
import Panel3FormPreview from './Panel3FormPreview';
import Panel3CSSEditor from './Panel3CSSEditor';

const Panel3Container = ({ 
  formElements, 
  onSubmit, 
  onReorder, 
  onDeleteElement,
  onEditElement,
  pages,
  setCurrentPageIndex,
  formStyles,
  onStyleUpdate
}) => {
  const [showCSSEditor, setShowCSSEditor] = useState(false);

  // Log formStyles to verify updates
  console.log('Panel3Container received formStyles:', formStyles);

  return (
    <div className="panel-3-container">
      <div className="panel-header">
        <button
          className={`mode-button ${!showCSSEditor ? 'active' : ''}`}
          onClick={() => setShowCSSEditor(false)}
          title="Layout Mode"
        >
          <Layout size={16} />
          Layout
        </button>
        <button
          className={`mode-button ${showCSSEditor ? 'active' : ''}`}
          onClick={() => setShowCSSEditor(true)}
          title="Style Editor"
        >
          <Palette size={16} />
          Styles
        </button>
      </div>

      <div className="panel-content">
        {showCSSEditor ? (
          <Panel3CSSEditor 
            formStyles={formStyles}
            onStyleUpdate={onStyleUpdate}
          />
        ) : (
          <Panel3FormPreview
            formElements={formElements}
            onSubmit={onSubmit}
            onReorder={onReorder}
            onDeleteElement={onDeleteElement}
            onEditElement={onEditElement}
            pages={pages}
            setCurrentPageIndex={setCurrentPageIndex}
          />
        )}
      </div>
    </div>
  );
};

export default Panel3Container;
