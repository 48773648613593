import React, { useState, useEffect, useRef } from 'react';
import { Upload, AlertCircle } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid'; // Import UUID for unique IDs

const Panel2LayoutEditor = ({ selectedElement, onAddElement, onCancelEdit, pages, currentPageIndex, editingElement }) => {
  const [label, setLabel] = useState('');
  const [font, setFont] = useState('Arial');
  const [textColor, setTextColor] = useState('#000000');
  const [isBold, setIsBold] = useState(false);
  const [fontSize, setFontSize] = useState(18);
  const [textType, setTextType] = useState('single');
  const [options, setOptions] = useState('');
  const [targetPageId, setTargetPageId] = useState('');
  const [buttonStyle, setButtonStyle] = useState('primary');
  const [selectedFile, setSelectedFile] = useState(null);
  const elementIdRef = useRef();
  const [videoFile, setVideoFile] = useState(null);
  const [autoplay, setAutoplay] = useState(false);
  const [videoFilePath, setVideoFilePath] = useState('');
  const [uploadError, setUploadError] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL; //

  useEffect(() => {
    // Populate fields if editing an element
    if (editingElement) {
      setLabel(editingElement.label || '');
      setFont(editingElement.font || 'Arial');
      setTextColor(editingElement.color || '#000000');
      setIsBold(editingElement.isBold || false);
      setFontSize(editingElement.fontSize || 18);
      setTextType(editingElement.textType || 'single');
      setOptions(editingElement.options ? editingElement.options.join(',') : '');
      setTargetPageId(editingElement.targetPageId || '');
      setButtonStyle(editingElement.buttonStyle || 'primary');
      // Handle additional properties as needed
    }
  }, [editingElement]);

  const handleAddElement = async () => {
    if (!selectedElement) return;

    // Generate a unique identifier for each element if we're adding a new one
    const id = editingElement ? editingElement.id : uuidv4();
    let newElement = {
      id,
      type: selectedElement,
      label,
    };

    

    if (selectedElement === 'document') {
      if (!selectedFile) {
        setUploadError('Please provide a document file');
        return;
      }

      const filePath = await handleDocumentUpload(); // Upload and get the file path
      if (!filePath) return;

      newElement = {
        ...newElement,
        filePath,
        fileName: selectedFile.name,
      };
    } else if (selectedElement === 'video') {
      if (!videoFile) {
        setUploadError('Please provide a video file');
        return;
      }

      const filePath = await handleVideoUpload(); // Upload and get the video file path
      if (!filePath) return;

      newElement = {
        ...newElement,
        filePath,
        autoplay,
      };
    }

    // Handle specific element types
    if (selectedElement === 'upload') {
      newElement.elementType = 'file';
    } else if (selectedElement === 'dropdown') {
      newElement.options = options.split(',');
    } else if (selectedElement === 'text') {
      newElement.textType = textType;
    } else if (selectedElement === 'navigation') {
      newElement = {
        ...newElement,
        targetPageId,
        elementType: 'button',
        buttonStyle,
      };
    } else if (selectedElement === 'label') {
      newElement = {
        ...newElement,
        font,
        color: textColor,
        isBold,
        fontSize,
      };
    }

    onAddElement(newElement);
    resetForm();
  };

  const resetForm = () => {
    setLabel('');
    setFont('Arial');
    setTextColor('#000000');
    setIsBold(false);
    setFontSize(18);
    setTextType('single');
    setOptions('');
    setTargetPageId('');
    setSelectedFile(null);
    setVideoFile(null);
    setAutoplay(false);
  };

  if (!selectedElement) {
    return <p>Please select an element from Panel 1.</p>;
  }


  const handleDocumentUpload = async () => {
    if (!selectedFile) {
      setUploadError('Please select a file');
      return false;
    }

    try {
      const formData = new FormData();
      formData.append('file', selectedFile);

      // Use environment variable for upload URL
      const uploadUrl = `${backendBaseUrl}/api/upload`;
      console.log('Uploading to:', uploadUrl);

      const response = await fetch(uploadUrl, {
        method: 'POST',
        body: formData,
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error(`Upload failed: ${response.status} ${await response.text()}`);
      }

      const data = await response.json();
      setUploadSuccess(true);
      setTimeout(() => setUploadSuccess(false), 3000);

      return data.filePath; // Return the uploaded file path
    } catch (error) {
      console.error('Upload error:', error);
      setUploadError(`Failed to upload file: ${error.message}`);
      return false;
    }
  };

  const handleVideoUpload = async () => {
    if (!videoFile) {
      setUploadError('Please select a video file');
      return false;
    }

    try {
      const formData = new FormData();
      formData.append('file', videoFile);

      const uploadUrl = `${backendBaseUrl}/api/upload`; // You might use a different endpoint for videos if needed
      console.log('Uploading to:', uploadUrl);

      const response = await fetch(uploadUrl, {
        method: 'POST',
        body: formData,
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error(`Upload failed: ${response.status} ${await response.text()}`);
      }

      const data = await response.json();
      setUploadSuccess(true);
      setTimeout(() => setUploadSuccess(false), 3000);

      return data.filePath; // Return the uploaded file path
    } catch (error) {
      console.error('Upload error:', error);
      setUploadError(`Failed to upload file: ${error.message}`);
      return false;
    }
  };

  const handleFileSelect = (e) => {
    if (e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
      setUploadError('');
    }
  };

  const handleVideoFileSelect = (e) => {
    if (e.target.files.length > 0) {
      setVideoFile(e.target.files[0]);
      setUploadError('');
    }
  };



  return (
    <div>
      <h3>{editingElement ? 'Edit Element' : 'Add New Element'}</h3>

      {/* Label field for all elements */}
      <div className="form-group">
        <label>
          {selectedElement === 'upload' ? 'Upload Button Label' : 'Label'}:
          <input
            type="text"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            placeholder="Enter label"
          />
        </label>
      </div>

       {/* Document upload fields */}
       {selectedElement === 'document' && (
        <div className="form-group document-upload">
          <label className="file-upload-label">
            <input
              type="file"
              onChange={handleFileSelect}
              className="file-input"
            />
            <div className="upload-button">
              <Upload size={16} />
              {selectedFile ? selectedFile.name : 'Choose File'}
            </div>
          </label>

          {uploadError && (
            <div className="error-message">
              <AlertCircle size={16} />
              {uploadError}
            </div>
          )}

          {uploadSuccess && (
            <div className="success-message">
              File uploaded successfully!
            </div>
          )}
        </div>
      )}

      {/* Label-specific fields */}
      {selectedElement === 'label' && (
        <>
          <div className="form-group">
            <label>
              Font:
              <select value={font} onChange={(e) => setFont(e.target.value)}>
                <option value="Arial">Arial</option>
                <option value="Times New Roman">Times New Roman</option>
                <option value="Helvetica">Helvetica</option>
                <option value="Georgia">Georgia</option>
                <option value="Verdana">Verdana</option>
              </select>
            </label>
          </div>

          <div className="form-group">
            <label>
              Text Color:
              <input
                type="color"
                value={textColor}
                onChange={(e) => setTextColor(e.target.value)}
              />
            </label>
          </div>

          <div className="form-group">
            <label>
              Font Size:
              <input
                type="number"
                value={fontSize}
                min="8"
                max="72"
                onChange={(e) => setFontSize(parseInt(e.target.value, 10))}
              />
            </label>
          </div>

          <div className="form-group">
            <label>
              Bold:
              <input
                type="checkbox"
                checked={isBold}
                onChange={(e) => setIsBold(e.target.checked)}
              />
            </label>
          </div>
        </>
      )}

      {/* Dropdown-specific fields */}
      {selectedElement === 'dropdown' && (
        <div className="form-group">
          <label>
            Options (comma-separated):
            <input
              type="text"
              value={options}
              onChange={(e) => setOptions(e.target.value)}
              placeholder="Option1, Option2, Option3"
            />
          </label>
        </div>
      )}


      {/* Text-specific fields */}
      {selectedElement === 'text' && (
        <div className="form-group">
          <label>Text Input Type:</label>
          <div>
            <label>
              <input
                type="radio"
                value="single"
                checked={textType === 'single'}
                onChange={(e) => setTextType(e.target.value)}
              />
              Single Line
            </label>
            <label>
              <input
                type="radio"
                value="multi"
                checked={textType === 'multi'}
                onChange={(e) => setTextType(e.target.value)}
              />
              Multi Line
            </label>
          </div>
        </div>
      )}


      {/* Video-specific fields */}
      {selectedElement === 'video' && (
        <>
          <div className="form-group">
            <label>
              Video File:
              <input
                type="file"
                accept="video/mp4"
                onChange={handleVideoFileSelect}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Autoplay:
              <input
                type="checkbox"
                checked={autoplay}
                onChange={(e) => setAutoplay(e.target.checked)}
              />
            </label>
          </div>
        </>
      )}

      {/* Navigation-specific fields */}
      {selectedElement === 'navigation' && (
        <>
          <div className="form-group">
            <label>
              Navigate to Page:
              <select
                value={targetPageId}
                onChange={(e) => setTargetPageId(e.target.value)}
              >
                <option value="">Select a page</option>
                {pages.map((page, index) => (
                  index !== currentPageIndex && (
                    <option key={page.id} value={page.id}>
                      {page.title}
                    </option>
                  )
                ))}
              </select>
            </label>
          </div>

          <div className="form-group">
            <label>
              Button Style:
              <select
                value={buttonStyle}
                onChange={(e) => setButtonStyle(e.target.value)}
              >
                <option value="primary">Primary</option>
                <option value="secondary">Secondary</option>
                <option value="outline">Outline</option>
                <option value="text">Text Only</option>
              </select>
            </label>
          </div>
        </>
      )}

      <button onClick={handleAddElement} className="add-element-button">
        {editingElement ? 'Update' : 'Add'} Element
      </button><br></br>
      <button onClick={onCancelEdit} className="cancel-button">
        Cancel
      </button>
    </div>
  );
};

export default Panel2LayoutEditor;