import React, { useState } from 'react';


const WidgetBaseStyle = () => {
  const [widgetBgColor, setWidgetBgColor] = useState('#ffffff');
  const [buttonBgColor, setButtonBgColor] = useState('#f8f9fa');
  const [buttonTextColor, setButtonTextColor] = useState('#333333');
  const [buttonFontFamily, setButtonFontFamily] = useState('Arial');
  const [buttonFontSize, setButtonFontSize] = useState('16');
  const [buttonFontWeight, setButtonFontWeight] = useState('normal');
  const [buttonBorderRadius, setButtonBorderRadius] = useState(4);
  const [saveStatus, setSaveStatus] = useState('');

  // Common font families
  const fontOptions = [
    'Arial',
    'Helvetica',
    'Times New Roman',
    'Georgia',
    'Verdana',
    'Tahoma',
    'Trebuchet MS',
    'Impact'
  ];

  const handleSave = async () => {
    // Collect all styles into an object
    const styleData = {
      widgetStyle: {
        backgroundColor: widgetBgColor
      },
      buttonStyle: {
        backgroundColor: buttonBgColor,
        color: buttonTextColor,
        fontFamily: buttonFontFamily,
        fontSize: `${buttonFontSize}px`,
        fontWeight: buttonFontWeight,
        borderRadius: `${buttonBorderRadius}px`
      }
    };

    try {
        setSaveStatus('Saving...');
        
        console.log('Sending style data:', styleData);  // Debug log
        
        const response = await fetch('http://localhost:3001/api/widget-style', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({ style: styleData })
        });

        // Check if response is ok before trying to parse JSON
        if (!response.ok) {
            const errorText = await response.text();
            console.error('Server response:', errorText);
            throw new Error(`Server responded with status: ${response.status}`);
        }

        const result = await response.json();
        
        if (result.success) {
            setSaveStatus('Saved successfully!');
            setTimeout(() => setSaveStatus(''), 3000);
        } else {
            setSaveStatus('Save failed: ' + (result.message || 'Unknown error'));
            setTimeout(() => setSaveStatus(''), 3000);
        }
    } catch (error) {
        console.error('Error saving style:', error);
        setSaveStatus('Save failed: ' + error.message);
        setTimeout(() => setSaveStatus(''), 3000);
    }
};

  return (
    <div className="widget-base-style-container" style={{ width: '95%', padding: '0 20px', marginBottom: '20px', margin: '0 auto' }}>
      <div className="panel" style={{ padding: '20px' }}>
        <h2 style={{ marginTop: 0, marginBottom: '20px', fontSize: '1.2rem', color: '#333' }}>Widget Base Style</h2>
        
        <div style={{ display: 'flex', gap: '20px', maxWidth: '100%' }}>
          {/* Left side: Widget Style Editor */}
          <div style={{ flex: '0 0 35%' }} className="panel">
            <h3 style={{ marginTop: 0, marginBottom: '15px', fontSize: '1.1rem', color: '#333' }}>Widget Style Editor</h3>
            <div className="panel-content">
              {/* Widget Background Color Picker */}
              <div className="style-control">
                <label style={{ display: 'block', marginBottom: '8px', fontWeight: '500' }}>
                  Widget Background Color
                </label>
                <div className="color-picker-container">
                  <input
                    type="color"
                    value={widgetBgColor}
                    onChange={(e) => setWidgetBgColor(e.target.value)}
                  />
                  <input
                    type="text"
                    value={widgetBgColor}
                    onChange={(e) => setWidgetBgColor(e.target.value)}
                    style={{ 
                      flex: 1,
                      padding: '6px',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      fontFamily: 'monospace'
                    }}
                  />
                </div>
              </div>

              {/* Button Background Color Picker */}
              <div className="style-control" style={{ marginTop: '20px' }}>
                <label style={{ display: 'block', marginBottom: '8px', fontWeight: '500' }}>
                  Button Background Color
                </label>
                <div className="color-picker-container">
                  <input
                    type="color"
                    value={buttonBgColor}
                    onChange={(e) => setButtonBgColor(e.target.value)}
                  />
                  <input
                    type="text"
                    value={buttonBgColor}
                    onChange={(e) => setButtonBgColor(e.target.value)}
                    style={{ 
                      flex: 1,
                      padding: '6px',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      fontFamily: 'monospace'
                    }}
                  />
                </div>
              </div>

              {/* Button Text Color Picker */}
              <div className="style-control" style={{ marginTop: '20px' }}>
                <label style={{ display: 'block', marginBottom: '8px', fontWeight: '500' }}>
                  Button Text Color
                </label>
                <div className="color-picker-container">
                  <input
                    type="color"
                    value={buttonTextColor}
                    onChange={(e) => setButtonTextColor(e.target.value)}
                  />
                  <input
                    type="text"
                    value={buttonTextColor}
                    onChange={(e) => setButtonTextColor(e.target.value)}
                    style={{ 
                      flex: 1,
                      padding: '6px',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      fontFamily: 'monospace'
                    }}
                  />
                </div>
              </div>

              {/* Button Font Family Selector */}
              <div className="style-control" style={{ marginTop: '20px' }}>
                <label style={{ display: 'block', marginBottom: '8px', fontWeight: '500' }}>
                  Button Font Family
                </label>
                <select
                  value={buttonFontFamily}
                  onChange={(e) => setButtonFontFamily(e.target.value)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    backgroundColor: 'white'
                  }}
                >
                  {fontOptions.map(font => (
                    <option key={font} value={font} style={{ fontFamily: font }}>
                      {font}
                    </option>
                  ))}
                </select>
              </div>

              {/* Button Font Size */}
              <div className="style-control" style={{ marginTop: '20px' }}>
                <label style={{ display: 'block', marginBottom: '8px', fontWeight: '500' }}>
                  Button Font Size (px)
                </label>
                <input
                  type="number"
                  value={buttonFontSize}
                  onChange={(e) => setButtonFontSize(e.target.value)}
                  min="8"
                  max="72"
                  style={{
                    width: '100%',
                    padding: '8px',
                    border: '1px solid #ddd',
                    borderRadius: '4px'
                  }}
                />
              </div>

              {/* Button Font Weight */}
              <div className="style-control" style={{ marginTop: '20px' }}>
                <label style={{ display: 'block', marginBottom: '8px', fontWeight: '500' }}>
                  Font Weight
                </label>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <button
                    onClick={() => setButtonFontWeight('normal')}
                    style={{
                      flex: 1,
                      padding: '8px',
                      backgroundColor: buttonFontWeight === 'normal' ? '#007bff' : '#f8f9fa',
                      color: buttonFontWeight === 'normal' ? 'white' : '#333',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      cursor: 'pointer'
                    }}
                  >
                    Normal
                  </button>
                  <button
                    onClick={() => setButtonFontWeight('bold')}
                    style={{
                      flex: 1,
                      padding: '8px',
                      backgroundColor: buttonFontWeight === 'bold' ? '#007bff' : '#f8f9fa',
                      color: buttonFontWeight === 'bold' ? 'white' : '#333',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      cursor: 'pointer'
                    }}
                  >
                    Bold
                  </button>
                </div>
              </div>

              {/* Button Border Radius */}
              <div className="style-control" style={{ marginTop: '20px' }}>
                <label style={{ display: 'block', marginBottom: '8px', fontWeight: '500' }}>
                  Button Corner Radius (px)
                </label>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <input
                    type="range"
                    min="0"
                    max="40"
                    value={buttonBorderRadius}
                    onChange={(e) => setButtonBorderRadius(parseInt(e.target.value))}
                    style={{ flex: 1 }}
                  />
                  <input
                    type="number"
                    value={buttonBorderRadius}
                    onChange={(e) => setButtonBorderRadius(parseInt(e.target.value))}
                    min="0"
                    max="40"
                    style={{
                      width: '60px',
                      padding: '4px',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      textAlign: 'center'
                    }}
                  />
                </div>
              </div>

              {/* Save Button */}
              <div className="style-control" style={{ marginTop: '30px' }}>
                <button
                  onClick={handleSave}
                  style={{
                    width: '100%',
                    padding: '12px',
                    backgroundColor: '#28a745',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    fontSize: '1rem',
                    fontWeight: '500',
                    marginBottom: saveStatus ? '10px' : '0'
                  }}
                >
                  Save Widget Style
                </button>
                {saveStatus && (
                  <div 
                    style={{
                      padding: '10px',
                      textAlign: 'center',
                      backgroundColor: saveStatus.includes('success') ? '#d4edda' : '#f8d7da',
                      color: saveStatus.includes('success') ? '#155724' : '#721c24',
                      borderRadius: '4px'
                    }}
                  >
                    {saveStatus}
                  </div>
                )}
              </div>
            </div>
          </div>
          
          {/* Right side: Sample Widget Container */}
          <div style={{ flex: '0 0 55%' }} className="panel">
            <h3 style={{ marginTop: 0, marginBottom: '15px', fontSize: '1.1rem', color: '#333' }}>Sample Widget</h3>
            <div className="panel-content" style={{ display: 'flex', justifyContent: 'center' }}>
              {/* Sample Widget */}
              <div 
                className="sample-widget"
                style={{
                  width: '450px',
                  height: '800px',
                  backgroundColor: widgetBgColor,
                  border: '1px solid #ddd',
                  borderRadius: '8px',
                  padding: '20px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                }}
              >
                {/* Button Container - Top Half */}
                <div 
                  style={{
                    height: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    padding: '20px',
                  }}
                >
                  {/* First Row of Buttons */}
                  <div style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                    {[1, 2].map(num => (
                      <button 
                        key={num}
                        className="widget-button"
                        style={{
                          width: '160px',
                          height: '80px',
                          border: '1px solid #ddd',
                          borderRadius: `${buttonBorderRadius}px`,
                          backgroundColor: buttonBgColor,
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: `${buttonFontSize}px`,
                          fontFamily: buttonFontFamily,
                          fontWeight: buttonFontWeight,
                          color: buttonTextColor,
                        }}
                      >
                        Button {num}
                      </button>
                    ))}
                  </div>
                  
                  {/* Second Row of Buttons */}
                  <div style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                    {[3, 4].map(num => (
                      <button 
                        key={num}
                        className="widget-button"
                        style={{
                          width: '160px',
                          height: '80px',
                          border: '1px solid #ddd',
                          borderRadius: `${buttonBorderRadius}px`,
                          backgroundColor: buttonBgColor,
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: `${buttonFontSize}px`,
                          fontFamily: buttonFontFamily,
                          fontWeight: buttonFontWeight,
                          color: buttonTextColor,
                        }}
                      >
                        Button {num}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetBaseStyle;