import React, { useState } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { GripVertical, Trash2, Edit, FileDown } from 'lucide-react';

// SortableFormElement is defined as a separate component within the same file
const SortableFormElement = ({ 
  element, 
  index, 
  formData, 
  handleInputChange,
  onDeleteElement,
  onEditElement,
  pages,
  setCurrentPageIndex
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: element.id || `element-${index}` });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const renderFormElement = () => {
    const name = element.id || `element${index}`;
    const backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

    switch (element.type) {
      case 'text':
        return (
          <label>
            {element.label}
            {element.textType === 'multi' ? (
              <textarea
                value={formData[name] || ''}
                onChange={(e) => handleInputChange(name, e.target.value)}
                rows="4"
              />
            ) : (
              <input
                type="text"
                value={formData[name] || ''}
                onChange={(e) => handleInputChange(name, e.target.value)}
              />
            )}
          </label>
        );

        case 'video':
          return (
            <div key={name} className="form-group video-element">
              <label>{element.label}</label>
              {element.filePath && (
                <video
                  controls
                  autoPlay={element.autoplay}
                  width="100%"
                  style={{ marginTop: '10px' }}
                >
                  <source src={`${backendBaseUrl}${element.filePath}`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          );

      case 'checkbox':
        return (
          <label>
            <input
              type="checkbox"
              checked={formData[name] || false}
              onChange={(e) => handleInputChange(name, e.target.checked)}
            />{' '}
            {element.label}
          </label>
        );

      case 'dropdown':
        return (
          <label>
            {element.label}
            <select
              value={formData[name] || ''}
              onChange={(e) => handleInputChange(name, e.target.value)}
            >
              <option value="">Select an option</option>
              {element.options?.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </label>
        );

      case 'navigation':
        return (
          <button
            type="button"
            className={`navigation-button ${element.buttonStyle || 'primary'}`}
            onClick={() => {
              if (pages && setCurrentPageIndex) {
                const targetPageIndex = pages.findIndex(p => p.id === element.targetPageId);
                if (targetPageIndex !== -1) {
                  setCurrentPageIndex(targetPageIndex);
                }
              }
            }}
          >
            {element.label}
          </button>
        );

        case 'upload':
      return (
        <div className="styled-form-group" key={element.id}>
          <label className="styled-label" htmlFor={name}>{element.label}</label>
          <input
            type="file"
            className="styled-upload-input"
            id={name}
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                console.log('File selected:', file.name);
              }
            }}
          />
        </div>
      );

      case 'submit3':
        return (
          <button type="submit">{element.label || 'Submit'}</button>
        );

        case 'label':
          return (
            <div className="styled-form-group" key={element.id}>
              <div
                className="styled-form-label"
                style={{
                  fontFamily: element.font || 'Arial',
                    color: element.color || '#000000',
                    fontSize: `${element.fontSize || '18'}px`,
                    fontWeight: element.isBold ? 'bold' : 'normal',
                    margin: '10px 0',
                    display: 'block',
                }}
              >
                {element.text || element.label || 'Label text not provided'}
              </div>
            </div>
          );

      case 'document':
        return (
          <div className="document-element">
            <label>{element.label}</label>
            <div className="document-link">
              <a 
                href={`${backendBaseUrl}${element.filePath}`}
                target="_blank"
                rel="noopener noreferrer"
                className="download-link"
              >
                <FileDown size={16} />
                {element.fileName || 'Download Document'}
              </a>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="draggable-element"
      {...attributes}
    >
      <div className="element-controls">
        <div className="drag-handle" {...listeners}>
          <GripVertical size={16} />
        </div>
        {element.type !== '-' && (
          <>
            <button 
              type="button"
              className="edit-button"
              onClick={() => onEditElement(element, index)}
              title="Edit element"
            >
              <Edit size={16} />
            </button>
            <button 
              type="button"
              className="delete-button"
              onClick={() => onDeleteElement(index)}
              title="Delete element"
            >
              <Trash2 size={16} />
            </button>
          </>
        )}
      </div>
      {renderFormElement()}
    </div>
  );
};

// Utility function to remove duplicates from elements
const removeDuplicates = (elements) => {
  return elements.filter((element, index, self) =>
    index === self.findIndex((e) => (
      e.id === element.id
    ))
  );
};

// Main Panel3FormPreview component
const Panel3FormPreview = ({ 
  formElements, 
  onSubmit, 
  onReorder, 
  onDeleteElement,
  onEditElement,
  pages,
  setCurrentPageIndex
}) => {
  const [formData, setFormData] = useState({});

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleInputChange = (name, value) => {
    setFormData(prev => {
      const newFormData = {
        ...prev,
        [name]: value,
      };
      return newFormData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Remove duplicates from formElements before submitting
    const uniqueFormElements = removeDuplicates(formElements);
    onSubmit(uniqueFormElements);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    
    if (active.id !== over.id) {
      const oldIndex = formElements.findIndex(
        (element) => (element.id || `element-${element.index}`) === active.id
      );
      const newIndex = formElements.findIndex(
        (element) => (element.id || `element-${element.index}`) === over.id
      );
      
      let updatedFormElements = arrayMove(formElements, oldIndex, newIndex);
      updatedFormElements = removeDuplicates(updatedFormElements);
      onReorder(updatedFormElements);
    }
  };

  // Remove duplicates in formElements
  const uniqueFormElements = removeDuplicates(formElements);

  return (
    <div className="form-preview">
      <h3>Form Preview:</h3>
      <form onSubmit={handleSubmit}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={uniqueFormElements.map((el, i) => el.id || `element-${i}`)}
            strategy={verticalListSortingStrategy}
          >
            {uniqueFormElements.map((element, index) => (
              <SortableFormElement
                key={element.id || `element-${index}`}
                element={element}
                index={index}
                formData={formData}
                handleInputChange={handleInputChange}
                onDeleteElement={onDeleteElement}
                onEditElement={onEditElement}
                pages={pages}
                setCurrentPageIndex={setCurrentPageIndex}
              />
            ))}
          </SortableContext>
        </DndContext>
      </form>
    </div>
  );
};

export default Panel3FormPreview;