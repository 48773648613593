import React, { useState } from 'react';
import { 
  User,
  Home,
  Settings,
  Mail,
  Calendar,
  Bell,
  Search,
  Heart,
  Star,
  Phone
} from 'lucide-react';

const IconSelector = ({ onIconSelect, onConfirm, buttonText }) => {
  const [selectedIcon, setSelectedIcon] = useState('');
  
  // Add stroke color and explicit size to make icons more visible
  const iconProps = {
    size: 24,
    strokeWidth: 1.5,
    color: '#333333'
  };

  const iconList = [
    { name: 'User', component: User, label: 'User' },
    { name: 'Home', component: Home, label: 'Home' },
    { name: 'Settings', component: Settings, label: 'Settings' },
    { name: 'Mail', component: Mail, label: 'Mail' },
    { name: 'Calendar', component: Calendar, label: 'Calendar' },
    { name: 'Bell', component: Bell, label: 'Notifications' },
    { name: 'Search', component: Search, label: 'Search' },
    { name: 'Heart', component: Heart, label: 'Favorite' },
    { name: 'Star', component: Star, label: 'Star' },
    { name: 'Phone', component: Phone, label: 'Contact' }
  ];

  const styles = {
    container: {
      padding: '20px',
      marginBottom: '20px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      backgroundColor: '#ffffff'
    },
    button: {
      padding: '10px 20px',
      backgroundColor: '#0066cc',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      marginRight: '10px'
    },
    iconGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
      gap: '10px',
      padding: '10px',
      marginTop: '20px'
    },
    iconButton: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '15px',
      border: '1px solid #ddd',
      borderRadius: '4px',
      cursor: 'pointer',
      backgroundColor: 'white',
      transition: 'all 0.2s',
      gap: '8px'  // Space between icon and text
    },
    preview: {
      marginTop: '20px',
      padding: '20px',
      border: '1px solid #ddd',
      borderRadius: '4px',
      backgroundColor: '#f9f9f9'
    },
    iconWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '40px',
      height: '40px',
      borderRadius: '4px',
      backgroundColor: '#f5f5f5'
    },
    iconLabel: {
      fontSize: '12px',
      color: '#666',
      textAlign: 'center',
      marginTop: '4px'
    }
  };

  const handleIconSelect = (iconName) => {
    setSelectedIcon(iconName);
    if (onIconSelect) onIconSelect(iconName);
  };

  const handleConfirm = () => {
    if (selectedIcon && onConfirm) {
      onConfirm(selectedIcon);
    }
  };

  return (
    <div style={styles.container}>
      <h3 style={{ marginTop: 0 }}>Select Button Icon</h3>

      {selectedIcon && (
        <div style={styles.preview}>
          <h4>Preview:</h4>
          <button style={styles.button}>
            {React.createElement(
              iconList.find(icon => icon.name === selectedIcon)?.component,
              { ...iconProps, style: { marginRight: '8px' } }
            )}
            {buttonText}
          </button>
        </div>
      )}

      <div style={styles.iconGrid}>
        {iconList.map(({ name, component: IconComponent, label }) => (
          <button
            key={name}
            onClick={() => handleIconSelect(name)}
            style={{
              ...styles.iconButton,
              backgroundColor: selectedIcon === name ? '#e6f0ff' : 'white',
              borderColor: selectedIcon === name ? '#0066cc' : '#ddd'
            }}
          >
            <div style={styles.iconWrapper}>
              <IconComponent {...iconProps} />
            </div>
            <span style={styles.iconLabel}>{label}</span>
          </button>
        ))}
      </div>

      <div style={{ marginTop: '20px' }}>
        <button
          onClick={handleConfirm}
          disabled={!selectedIcon}
          style={{
            ...styles.button,
            backgroundColor: selectedIcon ? '#0066cc' : '#cccccc',
            cursor: selectedIcon ? 'pointer' : 'not-allowed'
          }}
        >
          Confirm Selection
        </button>
      </div>
    </div>
  );
};

export default IconSelector;