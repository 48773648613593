import React from 'react';
import { Type, CheckSquare, List, Navigation, FileText, Tag, Upload, Send, Video } from 'lucide-react';

const Panel1ElementSelector = ({ onElementSelect }) => {
  return (
    <div className="element-selector">
      <h3>Select an Element to Add:</h3>
      <div className="element-buttons">
        <button onClick={() => onElementSelect('label')} className="element-button">
          <Tag size={16} /> Label
        </button>
        <button onClick={() => onElementSelect('text')} className="element-button">
          <Type size={16} /> Text Input
        </button>
        <button onClick={() => onElementSelect('checkbox')} className="element-button">
          <CheckSquare size={16} /> Checkbox
        </button>
        <button onClick={() => onElementSelect('dropdown')} className="element-button">
          <List size={16} /> Dropdown
        </button>
        <button onClick={() => onElementSelect('navigation')} className="element-button">
          <Navigation size={16} /> Navigation Button
        </button>
        <button onClick={() => onElementSelect('document')} className="element-button">
          <FileText size={16} /> Document
        </button>
        <button onClick={() => onElementSelect('upload')} className="element-button">
          <Upload size={16} /> Upload
        </button>
        <button onClick={() => onElementSelect('video')} className="element-button">
          <Video size={16} /> video
        </button>
        <button onClick={() => onElementSelect('submit3')} className="element-button">
          <Send size={16} /> Submit
        </button>
        
                
      </div>
    </div>
  );
};

export default Panel1ElementSelector;
