import React, { useState } from 'react';
import { StrictMode } from 'react';
import './App.css';
import { ChevronDown } from 'lucide-react';
import Panel4JSONViewer from './components/Panel4JSONViewer';
import Panel5SubmittedValues from './components/Panel5SubmittedValues';
import WidgetBaseStyle from './components/widgetBaseStyle';
import FormCreationFlow from './components/FormCreationFlow';

function App() {
  const [formData, setFormData] = useState({
    styles: {
      backgroundColor: '#ffffff'
    },
    pages: [
      {
        id: 'page-1',
        title: 'Page 1',
        elements: [],
        transitions: []
      }
    ]
  });
  const [submittedValues, setSubmittedValues] = useState(null);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);

  const handleFormSave = (newFormData) => {
    setFormData(newFormData);
    // Show success message or handle completion
  };

  const toggleAdvanced = () => {
    setIsAdvancedOpen(!isAdvancedOpen);
  };

  return (
    <StrictMode>
      <div className="dashboard-container">
        <section className="dashboard-section form-builder-section">
          <div className="form-builder-main-container">
            <h1 className="form-builder-title">Form Builder</h1>
            <WidgetBaseStyle />
            <FormCreationFlow 
              onFormNameSubmit={handleFormSave}
              initialFormData={formData}
              onFormDataChange={setFormData}
            />
          </div>
        </section>

        <section className={`dashboard-section advanced-section ${isAdvancedOpen ? 'open' : ''}`}>
          <div className="section-header clickable" onClick={toggleAdvanced}>
            <div className="header-content">
              <h2>Advanced</h2>
              <ChevronDown className={`collapse-icon ${isAdvancedOpen ? 'open' : ''}`} size={24} />
            </div>
          </div>
          <div className="section-content">
            <div className="advanced-panels-container">
              <div className="panel json-viewer-panel">
                <Panel4JSONViewer formJson={formData} />
              </div>
              <div className="panel submitted-values-panel">
                <Panel5SubmittedValues submittedValues={submittedValues} />
              </div>
            </div>
          </div>
        </section>
      </div>
    </StrictMode>
  );
}

export default App;