import React from 'react';

// Utility function to remove duplicate elements from the JSON
const removeDuplicates = (elements) => {
  const seenIds = new Set();
  return elements.filter((element) => {
    if (seenIds.has(element.id)) {
      return false;
    } else {
      seenIds.add(element.id);
      return true;
    }
  });
};

const Panel4JSONViewer = ({ formJson }) => {
  // Remove duplicates from formJson before rendering
  const scrubbedFormJson = {
    ...formJson,
    pages: formJson.pages.map((page) => ({
      ...page,
      elements: removeDuplicates(page.elements),
    })),
  };

  return (
    <div>
      <h3>JSON Representation:</h3>
      <pre>{JSON.stringify(scrubbedFormJson, null, 2)}</pre>
    </div>
  );
};

export default Panel4JSONViewer;
