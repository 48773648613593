import React, { useState } from 'react';
import { FileDown } from 'lucide-react';
import './StyledFormPreview.css';

// Utility function to remove duplicate elements from pages
const removeDuplicatesFromPages = (pages) => {
  return pages.map((page) => ({
    ...page,
    elements: page.elements.filter((element, index, self) =>
      index === self.findIndex((e) => e.id === element.id)
    ),
  }));
};

const Panel5StyledPreview = ({ pages, onSubmit, formStyles = { backgroundColor: '#ffffff' } }) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [formData, setFormData] = useState({});
  const [submitted, setSubmitted] = useState(false);
  

  // Remove duplicates initially to prevent double rendering
  const scrubbedPages = removeDuplicatesFromPages(pages);

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    setSubmitted(true);
    setTimeout(() => setSubmitted(false), 3000);
  };

  const handleFileChange = async (elementId, file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch( `${backendBaseUrl}/api/upload`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('File upload failed');
      }

      const data = await response.json();
      setFormData((prev) => ({
        ...prev,
        [elementId]: data.filePath, // Save only the file path in form data
      }));
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };
  const backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
  const renderFormElement = (element, index) => {
    const name = element.id || `element${index}`;
    switch (element.type) {
      case 'text':
        return (
          <div className="styled-form-group" key={element.id}>
            <label className="styled-label" htmlFor={name}>{element.label}</label>
            {element.textType === 'multi' ? (
              <textarea
                className="styled-textarea"
                id={name}
                value={formData[name] || ''}
                onChange={(e) => handleInputChange(name, e.target.value)}
                rows="4"
                placeholder={element.label}
              />
            ) : (
              <input
                className="styled-input"
                type="text"
                id={name}
                value={formData[name] || ''}
                onChange={(e) => handleInputChange(name, e.target.value)}
                placeholder={element.label}
              />
            )}
          </div>
        );

      case 'checkbox':
        return (
          <div className="styled-form-group checkbox" key={element.id}>
            <label className="styled-checkbox-label">
              <input
                type="checkbox"
                className="styled-checkbox"
                checked={formData[name] || false}
                onChange={(e) => handleInputChange(name, e.target.checked)}
              />
              <span className="checkbox-text">{element.label}</span>
            </label>
          </div>
        );

        case 'video':
          return (
            <div key={name} className="form-group video-element">
              <label>{element.label}</label>
              {element.filePath && (
                <video
                  controls
                  autoPlay={element.autoplay}
                  width="100%"
                  style={{ marginTop: '10px' }}
                >
                  <source src={`${backendBaseUrl}${element.filePath}`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          );

        case 'label':
            return (
              <div className="styled-form-group" key={element.id}>
                <div
                  className="styled-form-label"
                  style={{
                    fontFamily: element.font || 'Arial',
                    color: element.color || '#000000',
                    fontSize: `${element.fontSize || '18'}px`,
                    fontWeight: element.isBold ? 'bold' : 'normal',
                    margin: '10px 0',
                    display: 'block',
                  }}
                >
                  {element.text || element.label || 'Label text not provided'}
                </div>
              </div>
            );

      case 'dropdown':
        return (
          <div className="styled-form-group" key={element.id}>
            <label className="styled-label" htmlFor={name}>{element.label}</label>
            <select
              className="styled-select"
              id={name}
              value={formData[name] || ''}
              onChange={(e) => handleInputChange(name, e.target.value)}
            >
              <option value="">Please select...</option>
              {element.options?.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );

      case 'navigation':
        return (
          <div className="styled-form-group" key={element.id}>
            <button
              type="button"
              className={`styled-navigation-button ${element.buttonStyle || 'primary'}`}
              onClick={() => {
                const targetPageIndex = scrubbedPages.findIndex((p) => p.id === element.targetPageId);
                if (targetPageIndex !== -1) {
                  setCurrentPageIndex(targetPageIndex);
                }
              }}
            >
              {element.label}
            </button>
          </div>
        );

      case 'submit3':
        return (
          <div className="styled-form-group" key={element.id}>
            <button type="submit" className="styled-submit-button">
              {element.label || 'Submit'}
            </button>
          </div>
        );

        case 'upload':
          return (
            <div className="styled-form-group" key={element.id}>
              <label className="styled-label" htmlFor={name}>{element.label}</label>
              <input
                type="file"
                className="styled-upload-input"
                id={name}
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    handleFileChange(element.id, file);
                  }
                }}
              />
            </div>
          );

      case 'document':
        return (
          <div className="styled-form-group document-link" key={element.id}>
            <a
              href={`${backendBaseUrl}${element.filePath}`}
              target="_blank"
              rel="noopener noreferrer"
              className="document-text-link"
            >
              {element.label}
            </a>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="styled-preview-container">
      <div className="styled-preview-header">
        <h3>Final Form Preview</h3>
        <div className="page-indicator">
          Page {currentPageIndex + 1} of {scrubbedPages.length}
        </div>
      </div>

      {submitted && (
        <div className="success-message">
          Form submitted successfully!
        </div>
      )}

      <form onSubmit={handleSubmit} className="styled-form" style={formStyles}>
        <div className="form-page">
          {scrubbedPages[currentPageIndex].elements.map((element, index) =>
            renderFormElement(element, index)
          )}
        </div>
      </form>
    </div>
  );
};

export default Panel5StyledPreview;
