import React, { useState, useEffect } from 'react';
import IconSelector from './IconSelector';
import Panel1ElementSelector from './Panel1ElementSelector';
import Panel2LayoutEditor from './Panel2LayoutEditor';
import Panel3Container from './Panel3Container';
import Panel5StyledPreview from './Panel5StyledPreview';

// Default form data structure
const defaultFormData = {
  styles: {
    backgroundColor: '#ffffff'
  },
  pages: [
    {
      id: 'page-1',
      title: 'Page 1',
      elements: [],
      transitions: []
    }
  ]
};

const FormCreationFlow = ({ onFormNameSubmit }) => {
  const [topics, setTopics] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [formName, setFormName] = useState('');
  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedIcon, setSelectedIcon] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [editingElement, setEditingElement] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [activePanel, setActivePanel] = useState('panel1');
  const [formData, setFormData] = useState(defaultFormData);

  const styles = {
    container: {
      padding: '20px',
      marginBottom: '20px',
      border: '1px solid #ccc',
      borderRadius: '4px',
    },
    button: {
      padding: '10px 20px',
      backgroundColor: '#0066cc',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      marginRight: '10px'
    },
    input: {
      padding: '8px',
      marginRight: '10px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      width: '300px'
    },
    select: {
      padding: '8px',
      marginRight: '10px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      width: '300px',
      height: '40px'
    },
    saveButton: {
      padding: '15px 30px',
      backgroundColor: '#28a745',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: 'bold',
      marginTop: '20px'
    },
    formBuilderContainer: {
      marginTop: '20px',
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '4px'
    },
    errorMessage: {
      color: '#dc3545',
      marginTop: '10px',
      padding: '10px',
      backgroundColor: '#f8d7da',
      borderRadius: '4px'
    }
  };

  useEffect(() => {
    if (currentStep === 2) {
      fetchTopics();
    }
  }, [currentStep]);

  const fetchTopics = async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      const response = await fetch('http://localhost:3001/api/topics/23000');
      if (!response.ok) throw new Error('Failed to fetch topics');
      const data = await response.json();
      setTopics(Array.isArray(data) ? data : []);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartNewForm = () => setCurrentStep(1);
  
  const handleFormNameSubmit = () => formName.trim() && setCurrentStep(2);
  
  const handleTopicSubmit = () => selectedTopic && setCurrentStep(3);
  
  const handleIconConfirm = (iconName) => {
    setSelectedIcon(iconName);
    setCurrentStep(4);
  };

  const handleElementSelect = (element) => {
    setSelectedElement(element);
    setActivePanel('panel2');
  };

  const handleEditElement = (element, index) => {
    setSelectedElement(element.type);
    setEditingElement(element);
    setEditingIndex(index);
    setActivePanel('panel2');
  };

  const handleCancelEdit = () => {
    setSelectedElement(null);
    setEditingElement(null);
    setEditingIndex(null);
    setActivePanel('panel1');
  };

  const handleAddElement = (newElement) => {
    if (editingIndex !== null) {
      setFormData(prevData => {
        const updatedPages = [...prevData.pages];
        updatedPages[currentPageIndex].elements[editingIndex] = {
          ...updatedPages[currentPageIndex].elements[editingIndex],
          ...newElement,
          id: updatedPages[currentPageIndex].elements[editingIndex].id
        };
        return {
          ...prevData,
          pages: updatedPages
        };
      });
      setEditingElement(null);
      setEditingIndex(null);
    } else {
      const elementWithId = {
        ...newElement,
        id: `element-${Date.now()}`
      };
      setFormData(prevData => {
        const updatedPages = [...prevData.pages];
        updatedPages[currentPageIndex].elements.push(elementWithId);
        return {
          ...prevData,
          pages: updatedPages
        };
      });
    }
    setSelectedElement(null);
    setActivePanel('panel1');
  };

  const handleReorderElements = (newOrder) => {
    setFormData(prevData => {
      const updatedPages = [...prevData.pages];
      updatedPages[currentPageIndex].elements = newOrder;
      return {
        ...prevData,
        pages: updatedPages
      };
    });
  };

  const handleDeleteElement = (index) => {
    setFormData(prevData => {
      const updatedPages = [...prevData.pages];
      updatedPages[currentPageIndex].elements.splice(index, 1);
      return {
        ...prevData,
        pages: updatedPages
      };
    });
  };

  const handleStyleUpdate = (newStyles) => {
    setFormData(prevData => ({
      ...prevData,
      styles: {
        ...prevData.styles,
        ...newStyles,
      },
    }));
  };

  const handleSaveForm = async () => {
    setIsSaving(true);
    setSaveError(null);

    try {
      const response = await fetch('http://localhost:3001/api/form-items', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          account_id: 23000,
          button_text: formName,
          button_icon: selectedIcon,
          topic_id: selectedTopic,
          form_json: formData,
          requirepatient: true
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to save form');
      }

      const savedForm = await response.json();
      console.log('Form saved successfully:', savedForm);
      
      // Reset form
      setCurrentStep(1);
      setFormName('');
      setSelectedTopic('');
      setSelectedIcon('');
      setFormData(defaultFormData);

    } catch (error) {
      console.error('Error saving form:', error);
      setSaveError(error.message);
    } finally {
      setIsSaving(false);
    }
  };

  const renderFormBuilder = () => (
    <div className="form-builder-components-container">
      <div className="panel">
        <div className="form-builder-controls">
          <button onClick={() => setFormData(prevData => ({
            ...prevData,
            pages: [
              ...prevData.pages,
              {
                id: `page-${prevData.pages.length + 1}`,
                title: `Page ${prevData.pages.length + 1}`,
                elements: [],
                transitions: []
              }
            ]
          }))}>Add New Page</button>

          <div className="page-tabs">
            {formData.pages.map((page, index) => (
              <button
                key={page.id}
                onClick={() => setCurrentPageIndex(index)}
                className={`page-tab ${currentPageIndex === index ? 'active' : ''}`}
              >
                {page.title}
              </button>
            ))}
          </div>
        </div>

        <div className="form-builder-panels-container">
          <div className={`panel panel-1 ${activePanel === 'panel1' ? 'expanded' : 'collapsed'}`}>
            {activePanel === 'panel1' && (
              <Panel1ElementSelector onElementSelect={handleElementSelect} />
            )}
          </div>
          <div className={`panel panel-2 ${activePanel === 'panel2' ? 'expanded' : 'collapsed'}`}>
            {activePanel === 'panel2' && (
              <Panel2LayoutEditor
                selectedElement={selectedElement}
                onAddElement={handleAddElement}
                onCancelEdit={handleCancelEdit}
                pages={formData.pages}
                currentPageIndex={currentPageIndex}
                editingElement={editingElement}
              />
            )}
          </div>
          <div className="panel panel-3">
            <Panel3Container
              formElements={formData.pages[currentPageIndex].elements}
              onSubmit={() => {}}
              onReorder={handleReorderElements}
              onDeleteElement={handleDeleteElement}
              onEditElement={handleEditElement}
              pages={formData.pages}
              setCurrentPageIndex={setCurrentPageIndex}
              formStyles={formData.styles}
              onStyleUpdate={handleStyleUpdate}
            />
          </div>
          <div className="panel panel-styled-preview">
            <Panel5StyledPreview
              pages={formData.pages}
              onSubmit={() => {}}
              formStyles={formData.styles}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div style={{ marginTop: '20px' }}>
      {currentStep === 1 && (
        <>
          <button style={styles.button} onClick={handleStartNewForm}>
            Create New Form
          </button>
          <div style={styles.container}>
            <h3 style={{ marginTop: 0 }}>Step 1: Form Name</h3>
            <div style={{ marginTop: '15px' }}>
              <input
                type="text"
                placeholder="Enter form name..."
                value={formName}
                onChange={(e) => setFormName(e.target.value)}
                style={styles.input}
              />
              <button 
                onClick={handleFormNameSubmit}
                disabled={!formName.trim()}
                style={{
                  ...styles.button,
                  backgroundColor: formName.trim() ? '#0066cc' : '#cccccc',
                  cursor: formName.trim() ? 'pointer' : 'not-allowed'
                }}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}

      {currentStep === 2 && (
        <div style={styles.container}>
          <h3 style={{ marginTop: 0 }}>Step 2: Select Topic</h3>
          <div style={{ marginTop: '15px' }}>
            {isLoading ? (
              <p>Loading topics...</p>
            ) : error ? (
              <p style={{ color: 'red' }}>{error}</p>
            ) : (
              <>
                <select
                  value={selectedTopic}
                  onChange={(e) => setSelectedTopic(e.target.value)}
                  style={styles.select}
                >
                  <option value="">Select a topic...</option>
                  {topics.map(topic => (
                    <option key={topic.id} value={topic.id}>
                      {topic.name}
                    </option>
                  ))}
                </select>
                <button 
                  onClick={handleTopicSubmit}
                  disabled={!selectedTopic}
                  style={{
                    ...styles.button,
                    backgroundColor: selectedTopic ? '#0066cc' : '#cccccc',
                    cursor: selectedTopic ? 'pointer' : 'not-allowed'
                  }}
                >
                  Next
                </button>
              </>
            )}
          </div>
        </div>
      )}

      {currentStep === 3 && (
        <IconSelector 
          onIconSelect={setSelectedIcon}
          onConfirm={handleIconConfirm}
          buttonText={formName}
        />
      )}

      {currentStep === 4 && (
        <div style={styles.formBuilderContainer}>
          <h3>Step 4: Create Form</h3>
          {renderFormBuilder()}
          {saveError && (
            <div style={styles.errorMessage}>
              {saveError}
            </div>
          )}
          <button
            onClick={handleSaveForm}
            disabled={isSaving || !formData}
            style={{
              ...styles.saveButton,
              opacity: isSaving || !formData ? 0.6 : 1,
              cursor: isSaving || !formData ? 'not-allowed' : 'pointer'
            }}
          >
            {isSaving ? 'Saving...' : 'Save Form'}
          </button>
        </div>
      )}
    </div>
  );
};

export default FormCreationFlow;