import React, { useState, useEffect } from 'react';
import { Palette, Check } from 'lucide-react';

const Panel3CSSEditor = ({ formStyles, onStyleUpdate }) => {
  // Local state for draft styles
  const [draftStyles, setDraftStyles] = useState(formStyles);
  const [showSuccess, setShowSuccess] = useState(false);

  // Update draft styles when formStyles prop changes
  useEffect(() => {
    setDraftStyles(formStyles);
  }, [formStyles]);

  const handleStyleChange = (newStyle) => {
    setDraftStyles(prev => ({
      ...prev,
      ...newStyle
    }));
  };

  const handleApplyStyles = () => {
    onStyleUpdate({ ...draftStyles }); // Pass the updated styles to the parent component
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 2000);
  };

  return (
    <div className="css-editor">
      <div className="css-editor-header">
        <h3>
          <Palette size={16} className="header-icon" />
          Form Style Editor
        </h3>
      </div>
      
      <div className="style-controls">
        <div className="style-control">
          <label>Background Color:</label>
          <div className="color-picker-container">
            <input
              type="color"
              value={draftStyles.backgroundColor}
              onChange={(e) => handleStyleChange({ backgroundColor: e.target.value })}
            />
            <input
              type="text"
              value={draftStyles.backgroundColor}
              onChange={(e) => handleStyleChange({ backgroundColor: e.target.value })}
              placeholder="#ffffff"
            />
          </div>
        </div>

        {/* Live Preview */}
        <div className="style-preview">
          <h4>Preview</h4>
          <div 
            className="preview-box"
            style={draftStyles}
          >
            Sample Form Background
          </div>
        </div>

        {/* Success Message */}
        {showSuccess && (
          <div className="success-message">
            <Check size={16} /> Styles applied successfully!
          </div>
        )}

        {/* Apply Button */}
        <button 
          className="apply-styles-button"
          onClick={handleApplyStyles}
        >
          Apply Styles
        </button>
      </div>
    </div>
  );
};

export default Panel3CSSEditor;